/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { Button, Switch } from '@mui/material';
import { cn } from '../../../lib/utils';
import { CheckIcon } from '@radix-ui/react-icons';
import { motion, useInView, m } from 'framer-motion';
import { Loader } from 'lucide-react';
import { useNavigate } from 'react-router';
import { getSubscriptions, getOnbaording } from '../../../redux/slices/subscription';
import { useDispatch, useSelector } from '../../../redux/store';
import './newPricing.css';
type Interval = 'month' | 'year';
const toHumanPrice = (price: any, decimals = 2) => {
  return Number(price / 100).toFixed(decimals);
};

const demoPrices = [
  {
    id: 'price_1',
    name: 'Basic',
    description: 'A basic plan for startups and individual users',
    features: [
      'AI-powered analytics',
      'Basic support',
      '5 projects limit',
      'Access to basic AI tools',
    ],
    monthlyPrice: 1000,
    yearlyPrice: 10000,
    isMostPopular: false,
  },
  {
    id: 'price_2',
    name: 'Premium',
    description: 'A premium plan for growing businesses',
    features: [
      'Advanced AI insights',
      'Priority support',
      'Unlimited projects',
      'Access to all AI tools',
      'Custom integrations',
    ],
    monthlyPrice: 2000,
    yearlyPrice: 20000,
    isMostPopular: true,
  },
  {
    id: 'price_5',
    name: 'Enterprise',
    description: 'An enterprise plan with advanced features for large organizations',
    features: [
      'Custom AI solutions',
      '24/7 dedicated support',
      'Unlimited projects',
      'Access to all AI tools',
      'Custom integrations',
      'Data security and compliance',
    ],
    monthlyPrice: 5000,
    yearlyPrice: 50000,
    isMostPopular: false,
  },
  {
    id: 'price_6',
    name: 'Ultimate',
    description: 'The ultimate plan with all features for industry leaders',
    features: [
      'Bespoke AI development',
      'White-glove support',
      'Unlimited projects',
      'Priority access to new AI tools',
      'Custom integrations',
      'Highest data security and compliance',
    ],
    monthlyPrice: 8000,
    yearlyPrice: 80000,
    isMostPopular: false,
  },
];

// const CheckIcon = () => (
//   <svg className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" viewBox="0 0 20 20" fill="currentColor">
//     <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
//   </svg>
// );

export function Pricing() {
  const [onboarding, setOnboarding] = useState({} as any);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const data = await getOnbaording();
      setOnboarding(data?.data);
    } catch (error) {
      console.log('Error::', error);
    }
  };

  //   const onSubscribeClick = async (priceId:any) => {
  //     setIsLoading(true);
  //     setId(priceId);
  //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
  //     setIsLoading(false);
  //   };
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state: any) => state.subscription);
  const colors = { backColor: '#1FA9FF', fontColor: '#FFFFFF', buttonBorder: '#1FA9FF' };
  useEffect(() => {
    dispatch(getSubscriptions() as any);
  }, [dispatch]);
  const [interval, setInterval] = useState<Interval>('month');
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const navigate = useNavigate();
  const onSubscribeClick = async (priceId: string) => {
    setIsLoading(true);
    setId(priceId);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
    if (interval == 'year') {
      localStorage.setItem('paymentType', JSON.stringify(true));
    } else {
      localStorage.setItem('paymentType', JSON.stringify(false));
    }
    localStorage.setItem('currentSubscriptionId', priceId);
    navigate('/checkout');
    setIsLoading(false);
  };
  const fadeInRef = useRef(null);
  const fadeInInView = useInView(fadeInRef, {
    once: true,
  });
  const fadeUpVariants = {
    initial: {
      opacity: 0,
      y: 24,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <section id="pricing" className="">
      <div className="mx-auto flex max-w-screen-xl flex-col gap-8 px-4 py-14 md:px-8">
        <div className="mx-auto max-w-5xl text-center">
          <m.h2
            ref={fadeInRef}
            style={{ paddingTop: '10px' }}
            className="text-4xl sm:text-5xl font-bold tracking-tight text-black dark:text-white md:text-6xl"
            animate={fadeInInView ? 'animate' : 'initial'}
            variants={fadeUpVariants}
            initial={false}
            transition={
              {
                duration: 0.6,
                delay: 0.1,
                ease: [0.21, 0.47, 0.32, 0.98],
                type: 'spring',
              } as any
            }
          >
            Your New Favorite Bill.
          </m.h2>

          <p className="mt-6 text-3xl leading-9 text-black/80 dark:text-white">
            <span className="font-bold text-red-600">Warning:</span> Taking this step will
            significantly increase your sales.
          </p>
          {/* <div className="flex items-center justify-center space-x-4 my-6">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={(e) => setInterval(e.target.checked ? "year" : "month")}
              />
              <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">Annual</span>
            </label>
            <span className="inline-block whitespace-nowrap rounded-full bg-black px-4 py-2 text-base font-semibold uppercase leading-5 tracking-wide text-white dark:bg-white dark:text-black">
              10% OFF ANNUAL
            </span>
          </div> */}
        </div>
        <div className="flex w-full items-center justify-center space-x-2 √">
          <Switch
            className="switch-custom"
            id="interval"
            onChange={(checked) => {
              if (interval == 'month') {
                setInterval('year');
              } else {
                setInterval('month');
              }
            }}
          />
          <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">Annual</span>
          <span className="inline-block whitespace-nowrap rounded-full bg-black px-4 py-2 text-base font-semibold uppercase leading-5 tracking-wide text-white dark:bg-white dark:text-black">
            10% Off Annual ✨
          </span>
        </div>
        <p className="self-center text-center w-auto sm:w-max text-xl font-bold text-black dark:text-white bg-yellow-200 dark:bg-yellow-800 px-4 py-2 my-4 rounded-md shadow-md">
          {/* A ${onboarding?.price ? onboarding.price : 1000} onboarding fee, plus your first month's
          fee, will be charged upon purchase. */}
          Get the full power of RealityFence free for 10 days. Cancel anytime.
        </p>

        <div className="mx-auto grid w-full sm:justify-center gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {subscriptions.map(
            (price: any, index: number) =>
              price.name.indexOf('Trial') < 0 && (
                <div
                  key={price.id}
                  className={`relative flex w-full max-w-[400px] flex-col gap-4 overflow-hidden rounded-2xl border p-4 text-black dark:text-white ${
                    price.isMostPopular
                      ? 'border-2 border-neutral-700 shadow-lg shadow-neutral-500 dark:border-neutral-400 dark:shadow-neutral-600'
                      : ''
                  }`}
                >
                  <div className="flex items-center">
                    <div className="ml-4">
                      <h2 className="text-base font-semibold leading-7">{price.name}</h2>
                      {price?.description && (
                        <p className="h-16 text-sm leading-5 text-black/70 dark:text-white">
                          {price?.description}
                        </p>
                      )}
                    </div>
                  </div>

                  <motion.div
                    key={`${price.id}-${interval}`}
                    initial={{ opacity: 0, y: 12 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.4,
                      delay: 0.1 + index * 0.05,
                      ease: [0.21, 0.47, 0.32, 0.98],
                    }}
                    className="flex flex-row gap-1"
                  >
                    <span className="text-2xl font-bold text-black dark:text-white">
                      $
                      {/* {interval === "year"
                            ? price.price
                            : price.price} */}{' '}
                      {interval === 'month'
                        ? price?.price != null
                          ? Math.round(price.price * 100) / 100
                          : 0
                        : price?.price != null && price.discount != null
                        ? (((price.price * (100 - price.discount)) / 100) * 12)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Divide by 100 to get the actual price
                        : 0}
                      <span className="text-xs"> / {interval} after trial</span>
                    </span>
                  </motion.div>

                  <button
                    className="group relative w-full gap-2 overflow-hidden text-lg font-semibold tracking-tighter bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-all duration-300 ease-out"
                    disabled={isLoading}
                    onClick={() => onSubscribeClick(price.id)}
                    style={{ backgroundColor: '#1FA9FF' }}
                  >
                    <span className="absolute right-0 -mt-12 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                    {(!isLoading || (isLoading && id !== price.id)) && <p>Act Now</p>}

                    {isLoading && id === price.id && <p>Subscribing...</p>}
                  </button>

                  <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />
                  {/* {price.features && price.features.length > 0 && (
                <ul className="flex flex-col gap-2 font-normal">
                  {price.features.map((feature, idx) => (
                    <li
                      key={idx}
                      className="flex items-center gap-3 text-xs font-medium text-black dark:text-white"
                    >
                      <CheckIcon />
                      <span className="flex">{feature}</span>
                    </li>
                  ))}
                </ul>
              )} */}
                  <ul className="flex flex-col gap-2 font-normal">
                    <li className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                      <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                      <span className="flex">{price.totalUsers} Users</span>
                    </li>
                    <li className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                      <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                      <span className="flex">Up to {price.totalFences} Fences</span>
                    </li>
                    <li className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                      <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                      <span className="flex">Premium support</span>
                    </li>
                    <li className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                      <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                      <span className="flex">One-Touch-Sharing</span>
                    </li>
                    <li className="flex items-center gap-3 text-xs font-medium text-black dark:text-white">
                      <CheckIcon className="h-5 w-5 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                      <span className="flex">Request custom Fences</span>
                    </li>
                  </ul>
                </div>
              )
          )}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
